.scorepanel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: right;
    background-color: #0c0c0c;
}

.scorepanel__header {
    border: 1px solid red;
    width: fit-content;
    margin-left: auto
    ;
}

.scorepanel__score {
    padding: 10px;
    font-weight: 500;
    font-size: 32px;
    color: aliceblue;
}

.scorepanel__score__span {
    font-weight: 500;
    font-size: 32px;
}