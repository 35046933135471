.container {
  display: flex;
  flex: 1.0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 100px;
  color: aliceblue;
}

.container__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.container__back {
}

.container__history {
  padding-right: 20px;
}

.textField {
  margin-bottom: 5px;
  width: 50;
  text-align: center;
  caret-color: #fff;
  border-bottom: yellow;
}

.number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 800;
  /* font-size: xx-large; */
  font-size: 100px;
  color: aliceblue;
}

.number2 {
  font-weight: 200;
  font-size: 25px;
}

.answer {
  font-family: "Times, Times New Roman, serif";
  font-weight: 100;
  text-align: center;
  color: #fff;
}

.formGroup {
  display: flex;
  align-items: center;
}

.alert {
  width: "100%";
  align-items: center;
}

.questionPanel {
  display: flex;
  flex-direction: column;
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div#question span {
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    flex: 1.0;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 80px;
    color: aliceblue;
  }
}
