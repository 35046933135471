.historyQuestion {
    border: 1px solid black;
    /* border-radius: 5px; */
    /* padding: 1px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: yellow;
}

.historyQuestion__correctIcon {
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
    color: green;
}

.historyQuestion__incorrectIcon {
    margin-top: auto;
    margin-bottom: auto;
    padding: 10px;
    color: red;
}