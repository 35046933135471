.nav {
    background: transparent;
    /* background: ${({scrollNav, firm}) => (firm || scrollNav ? '#000' : 'transparent')}; */
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
}

.nav__scroll {
    background: #000;
}

.nav__firm {
    margin-top: 0px;
}

.nav__container {
    height: 80px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
}

.nav__logo {
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
}

.nav__img {
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    width: 42px;
    height: 42px;
}

.mobile__icon {
    display: none;
}

.nav__menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    color: #fff;
}

.nav__item {
    height: 80px;
}

.nav__links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.nav__linksDisabled {
    color: gray;
    cursor: default;
}

.nav__linksActive {
    border-bottom: 3px solid #01bf71;
}

.nav__btn {
    display: flex;
    align-items: center;

    
}

.nav__btnLink {
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.nav__btnLink:hover {
    transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
}

@media screen and (max-width: 960px) {
	.nav{
		transition: 0.8s all ease;
	}
}

@media screen and (max-width: 768px) {
	.mobile__icon {
		display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
	}
	.nav__menu {
		display: none;
	}
	.nav__btn {
        display: none;
    }
}