* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

body {
  background-color: #0c0c0c;
}

.App-panel {
  display: flex;
  height: 400px;
}