.home {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
}

.home__wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
}

.home__link {
  text-decoration: none;
  color: #000;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.home__card {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 200px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.home__card:hover {
  transform: scale(1.04);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.home__img {
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
}

.home__h1 {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}

.home__h2 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.home__p {
  font-size: 1rem;
  text-align: center;
}
