.history {
    display: none;
    flex-direction: column;
    border-left: 1px solid gray;
    /* flex: 0.2; */
    overflow-y: scroll;
    /* -ms-overflow-style: none; */
    scrollbar-width: none;
    padding: 10px;
    height: 500px;
    background-color: #0c0c0c;

    /* background: #000;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10; */
}

.history::-webkit-scrollbar {
    display: none;
}

.history__header {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #73AD21;
    width: 100%;
    position: sticky;
    top: 0;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
}


@media screen and (max-width: 768px) {
	.history {
		display: none;
	}
}